import LoadingWhite from "../assets/loading-white.svg";
import LoadingPrimary from "../assets/loading-primary.svg";

function Loading(props: {
  className?: string;
  primary?: boolean;
  size?: "small" | "large";
}) {
  return (
    <img
      alt="Loading"
      className={
        (props.size === "small" ? "w-5 h-5 " : "w-10 h-10 ") + props.className
      }
      src={props.primary ? LoadingPrimary : LoadingWhite}
    />
  );
}

export default Loading;
