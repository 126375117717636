import { useEffect, useState } from "react"
import { AppTheme, Colors } from "../constants/Types"
import LocalStorage from "../utils/LocalStorage";

interface useThemeI {
    theme : AppTheme,
    setTheme: (theme: AppTheme) => void
}

const colors : Colors = {
    "light" : {
        SKELETON_SCREEN_BASE: '#d6dbdf',
        SKELETON_SCREEN_HIGHLIGHT: '#afb3b7',
    },
    "dark" : {
        SKELETON_SCREEN_BASE: '#1d1d1d',
        SKELETON_SCREEN_HIGHLIGHT: '#3f3d3d',
    }
}

const isSystemThemeDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

const DefaultTheme : AppTheme = {
    mode: isSystemThemeDark ? 'dark' : 'light',
    colors: isSystemThemeDark ? colors.dark : colors.light
}

const useTheme = () : useThemeI => {
    const [appTheme, setAppTheme] = useState<AppTheme>(LocalStorage.getTheme() ?? DefaultTheme);
    
    useEffect(() => {
        const tags = document.getElementsByTagName("html")
        if(tags[0]){
            if(appTheme.mode === 'light'){
                tags[0].classList.remove('dark')
            }
            else{
                tags[0].classList.add('dark')
            }
        }
    }, [appTheme])

    const setTheme = (t : AppTheme) => {
        t.colors = colors[t.mode];
        setAppTheme(t);
        LocalStorage.setTheme(t)
    }

    return {theme: appTheme, setTheme}
}

export default useTheme