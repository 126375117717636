import Skeleton from "react-loading-skeleton";
import useTheme from "../../hooks/useTheme";

function BookContentSkeleton() {
  const {theme} = useTheme()

  return (
    <div className="w-full my-4 rounded bg-foreground dark:bg-line text-black p-4 h-screen">
      <div className="">
        <Skeleton
          width="40%"
          height="2.5rem"
          baseColor={theme.colors.SKELETON_SCREEN_BASE}
          highlightColor={theme.colors.SKELETON_SCREEN_HIGHLIGHT}
          inline
          className="mb-4 mt-1"
        />
      </div>

      <Skeleton
        width="100%"
        height="1rem"
        baseColor={theme.colors.SKELETON_SCREEN_BASE}
        highlightColor={theme.colors.SKELETON_SCREEN_HIGHLIGHT}
        className="mb-2"
        count={10}
      />
      <Skeleton
        width="70%"
        height="1rem"
        baseColor={theme.colors.SKELETON_SCREEN_BASE}
        highlightColor={theme.colors.SKELETON_SCREEN_HIGHLIGHT}
        className="mb-2"
      />
    </div>
  );
}

export default BookContentSkeleton;
