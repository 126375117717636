import { useKunji } from "kunji-react";
import Books from "./Books";
import Button from "./Button";
import Avatar from "./Avatar";

function Dashboard() {
  const {user, logout, initiateAuthentication} = useKunji()

  return (
    <div className=" px-4 pb-4">
      <div className="w-full flex justify-center">
      <div className="text-4xl font-bold text-foreground py-8 font-custom">
      <img src="/icon.png" alt="SuperBook Icon" className="-ml-4 w-32 h-32 inline"/>
          Superbook
       </div>
      </div>

      <div className="my-4">
      {
        user ? <div className="flex gap-2 pr-4 text-foreground font-sans items-center p-4"> 
        <Avatar src={user.picture} />
        <span className="flex-grow font-bold">{user.fullName}</span>
        <Button label="Logout" onClick={() => logout()}/>
        </div>
 : <div className="flex gap-2 pr-4 text-foreground font-sans items-center p-4 rounded-md"> 
 <span className="flex-grow"></span>
 <Button label="Login or Sign up" onClick={() => initiateAuthentication()}/>
 </div>
 
      }
      </div>

      {
        user && <>
        {/* Logged In */}

      <h1 className="text-4xl text-comment py-8 font-bold">Your Superbooks</h1>
        <Books user/>
        </>
      }
      <h1 className="text-4xl text-comment py-8 font-bold">Public Superbooks</h1>
      <Books />
      <h1 className="text-4xl text-comment py-8 font-bold">What is Superbook?</h1>
      <div className="text-foreground py-2">
        Superbook uses Generative AI and technology to write a personlized book
        according to user's interest. It provides a well managed productivity
        book reading interface that is powered by AI and technology.
      </div>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/59SWoR5pFMk?si=6di5K2Vin_KxNUqv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <h1 className="text-4xl text-comment py-8 font-bold">Our Achievements</h1>
      <div className="text-foreground py-2 italic">
        <b>Superbook</b> won the 3rd Prize in Hackout 2023! Checkout our Final
        Presentation here:
      </div>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8GwlpPsDUSc?si=XC-ef-QH0JxCbKyh&amp;start=4958"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      
    </div>
  );
}

export default Dashboard;
