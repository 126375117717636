import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'

function MarkdownView(props : { children: string }) {
    return (
        <Markdown remarkPlugins={[remarkGfm]} className="prose max-w-none dark:prose-invert">
            {props.children}
        </Markdown>
    );
}

export default MarkdownView;