import Skeleton from "react-loading-skeleton";
import useTheme from "../../hooks/useTheme";

function BookIndexSkeleton() {
  const {theme} = useTheme()

  return (
    <div>
      <Skeleton
        width="100%"
        height="1rem"
        baseColor={theme.colors.SKELETON_SCREEN_BASE}
        highlightColor={theme.colors.SKELETON_SCREEN_HIGHLIGHT}
        className="mb-2"
      />
      <Skeleton
        width="50%"
        height="1rem"
        baseColor={theme.colors.SKELETON_SCREEN_BASE}
        highlightColor={theme.colors.SKELETON_SCREEN_HIGHLIGHT}
        className="ml-8 mb-2"
        count={5}
      />
    </div>
  );
}

export default BookIndexSkeleton;
