import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Book } from "../constants/Types";
import { toast } from "react-toastify";
import { generateQA, getBookById } from "../services/book.service";
import bookLottie from "../assets/Animation - 1697947171622.json";
import Lottie from "react-lottie-player";
import BookComponent from "./BookComponent";
import { MdArrowBack } from "react-icons/md";
import Button from "./Button";
import BookReader from "./book-reader";

function ViewBook() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState<Book>();

  useEffect(() => {
    const api = async () => {
      if (!id) return;

      try {
        setLoading(true);
        const res = await getBookById(id);
        setLoading(false);
        setBook(res);
        if (res.status === "PENDING") {
          // check when its completed
          setTimeout(() => api(), 2000);
        }
      } catch (error) {
        toast.error("The Book your requested does not exist");
        setLoading(false);
      }
    };

    api();
  }, [id]);

  const navigate = useNavigate();

  return (
    <div className="min-h-screen w-screen">
      <button
        className="text-foreground text-4xl fixed m-4 cursor-pointer hover:scale-105 z-50"
        onClick={() => navigate("/")}
      >
        <MdArrowBack />
      </button>
      {book && (
        <>
          {book.status === "COMPLETED" && (
            <BookReader book={book} />
          )}

          {book.status === "PENDING" && (
            <div className="flex flex-col text-foreground justify-center items-center h-screen">
              <h1 className="font-bold text-2xl">
                Your Book "{book.name}" is Being Written By AI
              </h1>
              <div className="text-sm text-comment my-4">
                {Math.floor((book.generatedTopics / book.totalTopics) * 100)}%
                Completed
              </div>
              <div className="w-96 h-96">
                <Lottie
                  animationData={bookLottie}
                  play
                  loop={true}
                  className="m-auto"
                />
              </div>
              <div>
                Your Book is queued and will be created in some time. You will
                see your book here when the AI is done writing the Book.
              </div>
            </div>
          )}

          {book.status === "FAILED" && (
            <div className="flex flex-col text-foreground justify-center items-center h-screen">
              <h1 className="font-bold text-2xl">
                FAILED: Sorry your Book "{book.name}" can not be generated due
                to Technical Errors.
              </h1>

              <div>
                Bugs and Crashes are something that happend the most when we
                present!
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ViewBook;
