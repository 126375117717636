import { ChangeEvent } from "react";

function NoteEditor(props: {
    name: string;
    value: string;
    placeholder?: string;
    size?: "small" | "large";
    type?: "text" | "password";
    required?: boolean;
    error?: boolean;
    multiline?: boolean;
    rows?: number;
    helperText?: string | false;
    className?: string;
    onSave : (note: string) => void;
    onChange?: (
      e:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>
    ) => void;
    onKeyDown?: (e: any) => void;
    onBlur?: (
      e:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>
    )  => void
}) 
{
    return (
        <div className="relative">
            <textarea
                className="rounded-md w-full my-5 px-4 py-3 bg-gray-300 dark:bg-background dark:text-foreground text-gray-800 leading-tight focus:outline-none focus:shadow-outline-gray-300 text-sm"
                placeholder={props.placeholder + (props.required ? "*" : "")}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                rows={props.rows ?? 3}
              />

              <button
                    className="absolute bottom-8 right-5 cursor-pointer bg-background dark:bg-foreground p-1 px-4 rounded-full text-sm text-foreground dark:text-background shadow-lg text-right"
                    onClick={() => {
                        props.onSave(props.value)
                    }}
                  >
                    Save
                  </button>
        </div>
    );
}

export default NoteEditor;