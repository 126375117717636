import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Book,
  QAType,
  chapterPopulated,
  topicPopulated,
} from "../constants/Types";
import { useParams } from "react-router";
import { getBookQAList } from "../services/book.service";
import { Link } from "react-router-dom";
import moment from "moment";

function QAList(props: {}) {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [qaList, setQAList] = useState<QAType[]>([]);

  useEffect(() => {
    const api = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const res = await getBookQAList(id);
        setLoading(false);
        setQAList(res);
      } catch (error) {
        toast.error("Question Answers not available");
        setLoading(false);
      }
    };

    api();
  }, [id]);

  return (
    <div className="w-full h-full">
      <h1 className="text-center text-4xl text-comment font-bold pt-8">
        Question & Answers
      </h1>
      <h5 className="font-bold text-lg text-center text-foreground my-4 underline">
        {qaList[0] && qaList[0].subjectName}
      </h5>
      <div className=" grid grid-cols-12">
        <div className="col-span-3"></div>
        <div className="col-span-6 py-8 flex flex-col">
          {qaList.map((qa, index) => {
            return (
              <Link
                to={"/book/qa/" + qa._id}
                className=" text-white bg-line m-4 p-4 rounded-lg shadow-sm hover:shadow-lg hover:scale-105 transition-all flex items-center"
              >
                <div className="">Question Answer {index + 1}</div>
                <div className=" text-sm text-gray-500 pl-4">
                  {qa.generatedQuestions} Questions
                </div>

                <div className=" text-sm text-gray-500 pl-4">
                  • {moment(qa.createdAt).fromNow()}
                </div>

                <div className="flex-grow"></div>
                <div
                  className={
                    "text-sm font-bold " +
                    (qa.status === "PENDING"
                      ? "text-yellow"
                      : qa.status === "COMPLETED"
                      ? "text-green"
                      : "text-red")
                  }
                >
                  {qa.status + " •"}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="col-span-3"></div>
      </div>
    </div>
  );
}

export default QAList;
