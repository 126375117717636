import React from "react";

interface ButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  className,
  disabled,
  outlined,
}) => {
  return (
    <button
      className={
        `transition-colors py-2 px-6 uppercase text-sm font-bold rounded-full ${className} ` +
        (disabled
          ? "opacity-50"
          : "hover:bg-gray-100 hover:text-background cursor-pointer") +
        " " +
        (outlined
          ? "bg-transparent  border-2 border-foreground text-foreground"
          : "bg-foreground text-background")
      }
      onClick={disabled ? (e) => e.preventDefault() : onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
