import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import useTheme from "./hooks/useTheme";

function App() {
  useTheme() // Important renders theme

  return (
    <>
      <div className="min-h-screen w-screen bg-background hidden md:block">
        <AppRoutes />
        <ToastContainer position="bottom-right" />
      </div>

      <div className="text-comment p-2 flex items-center justify-center h-screen w-screen bg-background md:hidden">
        Superbook is only supported on Desktop.
        <br /> Please open it from a Computer or Laptop.
      </div>
    </>
  );
}

export default App;
