import React from "react";

const data = [
  {
    name: "Quiz Name",
    level: 1, //  4 for 5 year old, 1 is normal, 2 is intermediate, 3 is experienced
    questions: [
      {
        title: "Question One",
        options: [" option 1", "option 2", "option 3", "option 4"],
        answer: 0,
      },
    ],
  },
];
function Quiz() {
  return <div>quiz page</div>;
}

export default Quiz;
