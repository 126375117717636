import { useState } from 'react';
import defaultAvatar from '../assets/default-avatar.svg';
function Avatar(props: { src?: string; className?: string; size?: 'sm' | 'md' | 'lg' }) {
  let sizeCss;

  const [image, setImage] = useState(props.src ?? defaultAvatar);
  if (props.size === 'md') {
    sizeCss = { width: 100, height: 100 };
  } else if (props.size === 'lg') {
    sizeCss = { width: 150, height: 150 };
  } else {
    sizeCss = { width: 30, height: 30 };
  }
  return (
    <img
      src={image}
      alt='Avatar'
      className={'rounded-full ' + props.className}
      style={sizeCss}
      onError={() => {
        setImage(defaultAvatar);
      }}
    />
  );
}

export default Avatar;