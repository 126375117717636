import axios from "axios";
import Config from ".";

const options = {
  baseURL: Config.BACKEND_ENDPOINT,
  headers: {},
};

const client = axios.create(options);

export default client;
