import { AppTheme } from "../constants/Types"

class LocalStorage {
    private static readonly THEME = '@theme';
  
    static setTheme(theme: AppTheme): void {
      localStorage.setItem(LocalStorage.THEME, JSON.stringify(theme));
    }
  
    static getTheme(): AppTheme | null {
        const u = localStorage.getItem(LocalStorage.THEME);
        return u ? (JSON.parse(u) as AppTheme) : null;
    }
  
    static clear(): void {
      localStorage.removeItem(LocalStorage.THEME);
    }
  }
  
  export default LocalStorage;