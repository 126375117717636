import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import CreateBookSchema from "./components/CreateBookSchema";
import ViewBook from "./components/ViewBook";
import Quiz from "./pages/Quiz";
import QAList from "./pages/QAList";
import QA from "./pages/QA";
import { useKunji } from "kunji-react";
import LoginToContinue from "./components/LoginToContinue";

function AppRoutes() {
  const {user} = useKunji()
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="book/questions/:id" element={<QAList />}></Route>
      <Route path="book/qa/:id" element={<QA />}></Route>
      <Route path="book/:id" element={<ViewBook />}></Route>
      <Route path="quiz" element={<Quiz />}></Route>
      <Route path="quiz/:id" element={<Quiz />}></Route>

      <Route path="create" element={user ? <CreateBookSchema /> : <LoginToContinue/>}></Route>
    </Routes>
  );
}

export default AppRoutes;
