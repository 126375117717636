import { ChangeEvent, useState } from "react";
import { MdError, MdVisibility, MdVisibilityOff } from "react-icons/md";

function InputField(props: {
  label?: string;
  name: string;
  value?: string | number;
  placeholder?: string;
  size?: "small" | "large";
  type?: "text" | "password";
  required?: boolean;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string | false;
  className?: string;
  options?: { value: string | number; label: string }[];
  onChange?: (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (e: any) => void;
  onBlur?: (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => void;
}) {
  const [isHidden, setHidden] = useState(true);
  let { type } = props;
  if (type !== "password" && type !== "text") {
    type = "text";
  }

  return (
    <div className={"my-2 " + props.className}>
      {props.label && (
        <label className="text-sm text-black my-2">
          {props.label} {props.required ? "*" : ""}
        </label>
      )}

      <div className="flex relative">
        {props.options ? (
          <>
            <select
              className={
                "rounded w-full my-2  bg-line text-foreground leading-tight focus:outline-none focus:shadow-outline-gray-300 text-sm " +
                (props.size === "small" ? "px-2 py-2" : "px-4 py-3")
              }
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
            >
              {/* <input type="text" placeholder="Search" /> */}
              <option value="">Not Selected</option>
              {props.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        ) : (
          <>
            {type === "text" && props.multiline ? (
              <textarea
                className="rounded w-full my-2 px-4 py-3 bg-line text-foreground leading-tight focus:outline-none focus:shadow-outline-gray-300 text-sm"
                placeholder={props.placeholder + (props.required ? "*" : "")}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                rows={props.rows ?? 3}
              />
            ) : (
              <input
                className="rounded w-full my-2 px-4 py-3 bg-line text-foreground leading-tight focus:outline-none focus:shadow-outline-gray-300 text-sm"
                type={!isHidden ? "text" : type ?? "text"}
                placeholder={
                  props.placeholder + " " + (props.required ? "*" : "")
                }
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
              />
            )}
          </>
        )}
        {type === "password" && (
          <>
            <button
              className="block text-gray-500 absolute right-4 h-full text-xl"
              onClick={(e) => {
                e.preventDefault();
                setHidden(!isHidden);
              }}
            >
              {!isHidden ? <MdVisibility /> : <MdVisibilityOff />}
            </button>
            <button
              className="block text-gray-500 absolute right-4 h-full text-xl"
              onClick={(e) => {
                e.preventDefault();
                setHidden(!isHidden);
              }}
            >
              {!isHidden ? <MdVisibility /> : <MdVisibilityOff />}
            </button>
          </>
        )}
      </div>
      <div
        className={
          "text-xsm text-red items-center justify-end gap-2 " +
          (props.error ? "flex" : "hidden")
        }
      >
        {props.helperText}
        <MdError className="text-lg" />
      </div>
    </div>
  );
}

export default InputField;
