import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { getBookIndex } from "../services/book.service";
import { BookIndexType } from "../constants/Types";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import BookIndexSkeleton from "./skeletons/BookIndexSkeleton";

const BookIndex = (props: {
  bookId: string;
  className?: string;
  onChapterChange: (chapterId: string) => void;
  onTopicChange: (topicId: string) => void;
}) => {
  const { bookId, onChapterChange, onTopicChange } = props;
  const [bookData, setBookData] = useState<BookIndexType>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      try {
        const data = await getBookIndex(bookId);
        setBookData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Syllabus can't be loaded");
      }
    };

    api();
  }, [bookId]); // Include bookId in the dependency array to re-run the effect when bookId changes

  return (
    <div
      className={
        "overflow-scroll bg-foreground dark:bg-line dark:text-foreground text-gray-700 py-4 rounded-t-lg shadow-2xl " +
        props.className
      }
    >
      <div className="p-4 h-full">
        <h2 className="text-center font-bold mb-4 underline">
          Table of Contents
        </h2>

        {isLoading && <BookIndexSkeleton />}

        {bookData &&
          bookData.chapters.map((chapter, index) => (
            <div key={chapter._id} className="mb-4 text-sm">
              <div
                className="font-semibold mb-2 hover:underline"
                onClick={() => onChapterChange(chapter._id)}
              >
                Chapter {index + 1}: {chapter.name}
              </div>
              <ul className="list-disc ml-6">
                {chapter.topics.map((topic) => (
                  <li
                    key={topic._id}
                    className="mb-2 hover:underline"
                    onClick={() => {
                      onChapterChange(chapter._id);
                      onTopicChange(topic._id);
                    }}
                  >
                    {topic.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BookIndex;
