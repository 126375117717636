import { useNavigate } from "react-router";
import Button from "./Button";
import { Book } from "../constants/Types";
import { generateQA } from "../services/book.service";
import ThemeToggle from "./ThemeToggle";
import useTheme from "../hooks/useTheme";

function MenuPanel(props : { book : Book}) {
    const {book} = props
    const navigate = useNavigate();

    return (
        <div className="flex flex-col gap-2 w-full">
        <h5 className="text-center text-comment my-2 font-bold">Menu Panel</h5>
        <Button
          label="View All QNA's"
          onClick={async () => {
            navigate("/book/questions/" + book.id);
          }}
        />
        
        <Button
          label="Generate QNA"
          onClick={async () => {
            const qa = await generateQA(book.id);
            navigate("/book/qa/" + qa._id);
          }}
        />

        <div className="my-4 border-b border-gray-800"></div>
        
        <ThemeToggle className="" />
        </div>
    );
}

export default MenuPanel;