import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Book,
  QAType,
  chapterPopulated,
  topicPopulated,
} from "../constants/Types";
import { useParams } from "react-router";
import { getBookQA } from "../services/book.service";
import bookLottie from "../assets/Animation - 1697947171622.json";
import moment from "moment";
import Lottie from "react-lottie-player";
import Markdown from "react-markdown";

function QA(props: {}) {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [qa, setQA] = useState<QAType>();

  useEffect(() => {
    const api = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const res = await getBookQA(id);
        setLoading(false);
        setQA(res);
        if (res.status === "PENDING") {
          // check when its completed
          setTimeout(() => api(), 2000);
        }
      } catch (error) {
        toast.error("Question Answer not available");
        setLoading(false);
      }
    };

    api();
  }, [id]);

  return (
    <div className="w-full h-full">
      {qa && (
        <>
          {qa.status === "PENDING" && (
            <div className="flex flex-col text-foreground justify-center items-center h-screen">
              <h1 className="font-bold text-2xl">
                Your Questions for "{qa.subjectName}" is being Written By AI
              </h1>
              <div className="text-sm text-comment my-4">
                {Math.floor((qa.generatedQuestions / qa.totalQuestions) * 100)}%
                Completed
              </div>
              <div className="w-96 h-96">
                <Lottie
                  animationData={bookLottie}
                  play
                  loop={true}
                  className="m-auto"
                />
              </div>
              <div>
                Your QNA is queued and will be created in some time. You will
                see your QNA here when its generated by the AI.
              </div>
            </div>
          )}

          {qa.status === "COMPLETED" && (
            <>
              <h1 className="text-center text-4xl text-comment pt-8 font-bold">
                Question & Answers
              </h1>
              <h5 className="font-bold text-lg text-center text-foreground my-4 underline">
                {qa.subjectName}
              </h5>

              <div className=" grid grid-cols-12">
                <div className="col-span-3"></div>
                <div className="col-span-6 py-8 flex flex-col">
                  <div className="w-full flex">
                    <div className=" text-sm text-gray-500 pr-4">
                      Total : {qa.generatedQuestions} Questions
                    </div>

                    <div className=" text-sm text-gray-500 px-4">
                      Created : {moment(qa.createdAt).fromNow()}
                    </div>
                  </div>

                  <div className="my-8 prose max-w-none">
                    {qa.data &&
                      qa.data.reverse().map((qa, index) => {
                        return (
                          <div className="text-foreground">
                            <div className="font-bold">
                              <Markdown>
                                {index + 1 + ". " + qa.question}
                              </Markdown>
                            </div>
                            <div className="text-comment">
                              <Markdown>{qa.answer}</Markdown>
                            </div>
                            <div className="text-xsm text-gray-500">
                              Topic: {qa.topic}, Chapter: {qa.chapter}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-span-3"></div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default QA;
