import { TbPlus } from "react-icons/tb";
import { useNavigate } from "react-router";
import Pages from "../constants/Pages";
import { Book } from "../constants/Types";
import { useEffect, useState } from "react";
import { getBookList , getBookListUser} from "../services/book.service";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useKunji } from "kunji-react";

function Books(props: {user?: boolean}) {
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState<Book[]>([]);
  const { oAxios } = useKunji()

  useEffect(() => {
    const api = async () => {
      try {
        setLoading(true);
        const res = props.user ? await getBookListUser(oAxios) : await getBookList();
        setLoading(false);
        setBooks(res);
      } catch (error) {
        toast.error("Unable to Load Books");
        setLoading(false);
      }
    };

    api();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="flex flex-wrap gap-2">
      {loading && (
        <Skeleton
          count={5}
          inline
          className="rounded m-2"
          baseColor="#1d1d1d"
          height="15rem"
          width="15rem"
          highlightColor="#3f3d3d"
        />
      )}

      {books.map((book, index) => {
        return (
          <Link
            key={index}
            to={"/book/" + book.id}
            className="relative bg-line w-60 h-60 text-foreground  flex flex-col items-center justify-center p-12 m-2 rounded hover:scale-105 hover:shadow-md transition-all cursor-pointer"
          >
            <b>{book.name}</b>
            <div className="text-xsm">
              Size: {book.size === 1 && "Small"}
              {book.size === 2 && "Medium"}
              {book.size === 3 && "Large"}
            </div>
            <div className="text-xsm opacity-50">
              {" "}
              {moment(book.createdAt).fromNow()}
            </div>
            <div className="text-xsm opacity-50">
              {" "}
              For <u>{book.username}</u>
            </div>
            <div
              className={
                "text-xsm font-bold absolute bottom-2 right-2 " +
                (book.status === "PENDING"
                  ? "text-yellow"
                  : book.status === "COMPLETED"
                  ? "text-green"
                  : "text-red")
              }
            >
              {book.status}
            </div>
          </Link>
        );
      })}

      <div
        className="bg-line w-60 h-60 text-foreground flex items-center justify-center p-12 m-2 rounded hover:scale-105 hover:shadow-md transition-all cursor-pointer"
        onClick={() => navigate("/create")}
      >
        <TbPlus size={40} />
      </div>
    </div>
  );
}

export default Books;
