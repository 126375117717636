import { AxiosInstance } from "axios";
import axios from "../config/axios";

import {
  Book,
  BookIndexType,
  QAType,
  SearchIndexResult,
  User,
  bookParams,
  bookSchema,
  chapterPopulated,
  topicPopulated,
} from "../constants/Types";

export const generateSyllabusForBook = async (
  bookName: string,
  level: number,
  user: User,
  prompt: string
) => {
  return await axios
    .post("book/syllabus", {
      name: bookName,
      level,
      user,
      prompt,
    })
    .then((res: any) => {
      return res.data as bookSchema;
    });
};

export const generateBook = async (oAxios: AxiosInstance, book: bookParams) => {
  return await oAxios.post("book", book).then((res: any) => {
    return res.data;
  });
};

export const getBookById = async (id: string) => {
  return await axios.get("book/" + id).then((res: any) => {
    return res.data as Book;
  });
};

export const getChapterById = async (id: string) => {
  return await axios.get("book/chapter/" + id).then((res: any) => {
    return res.data as chapterPopulated;
  });
};

export const updateTopicById = async (
  topic: topicPopulated,
  operation: "LEVEL" | "LANGUAGE" | "NOTES"
) => {
  return await axios
    .patch("book/topic/" + topic.id, {
      topic,
      operation,
    })
    .then((res: any) => {
      return res.data as topicPopulated;
    });
};

export const getBookListUser = async (oAxios: AxiosInstance,) => {
  return await oAxios.get("book/user").then((res: any) => {
    return res.data as Book[];
  });
};

export const getBookList = async () => {
  return await axios.get("book").then((res: any) => {
    return res.data as Book[];
  });
};

export const getBookQAList = async (bookId: string) => {
  return await axios.get("book/" + bookId + "/qa").then((res: any) => {
    return res.data as QAType[];
  });
};

export const getBookQA = async (qaId: string) => {
  return await axios.get("book/qa/" + qaId).then((res: any) => {
    return res.data as QAType;
  });
};

export const generateQA = async (bookId: string) => {
  return await axios.post("book/qa/" + bookId).then((res: any) => {
    return res.data as QAType;
  });
};

export const getBookIndex = async (bookId: string) => {
  return await axios.get("book/index/" + bookId).then((res: any) => {
    return res.data as BookIndexType;
  });
};

export const getExplanationFromGPT = async (sentence: string) => {
  return await axios
    .get("book/explain", {
      params: {
        sentence: sentence,
      },
    })
    .then((res: any) => {
      return res.data as string;
    });
};

export const searchBook = async (bookId: string, q: string) => {
  return await axios
    .get("book/search/" + bookId, {
      params: {
        q: q,
      },
    })
    .then((res: any) => {
      return res.data as SearchIndexResult[];
    });
};
