import { useState } from "react";
import { bookParams } from "../constants/Types";
import { useFormik } from "formik";
import InputField from "./InputField";
import * as yup from "yup";
import Button from "./Button";
import { MdAutoFixHigh, MdClose, MdAdd } from "react-icons/md";
import {
  generateBook,
  generateSyllabusForBook,
} from "../services/book.service";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useKunji } from "kunji-react";

const TOTAL_STEP = 3;

function CreateBookSchema() {
  const {user, oAxios} = useKunji()
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    // name: yup.string().required().min(3).label("Subject Name"),
    // level: yup.number().required().label("Level"),
  });

  const navigate = useNavigate();

  const autogenerateBookHandler = async (data: bookParams) => {
    try {
      setLoading(true);
      const res = await generateBook(oAxios, data);

      setLoading(false);

      if (res) {
        navigate("/book/" + res);
      }
      console.debug(res);
    } catch (error) {
      toast.error("Unable to generate the book. Try again");
      setLoading(false);
    }
  };

  const autogenerateSyllabusHandler = async () => {
    try {
      setLoading(true);
      const res = await generateSyllabusForBook(
        formik.values.schema.name,
        formik.values.level,
        formik.values.user,
        prompt
      );

      setLoading(false);
      if (res.name && res.chapters) {
        formik.setFieldValue("name", res.name);
        formik.setFieldValue("schema.chapters", res.chapters);
      } else {
        toast.error("Unable to auto generate syllabus. Try again");
      }
      console.debug(res);
    } catch (error) {
      toast.error("Unable to auto generate syllabus. Try again");
      setLoading(false);
    }
  };
  const [prompt, setPrompt] = useState("");
  const formik = useFormik<bookParams>({
    initialValues: {
      level: 1,
      schema: {
        size: 2,
        name: "",
        chapters: [],
      },
      user: {
        name: user?.fullName ?? "",
        interests: [],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.debug(values);
      autogenerateBookHandler(values);
    },
  });

  const [step, setStep] = useState(0);

  return (
    <div className="p-4">
      <h1 className="text-4xl text-comment py-8 font-bold">Create Book Syllabus</h1>
      {step === 0 && (
        <>
          <h4 className="text-white font-bold">
            Step - 1 : Enter Book Details
          </h4>
          <InputField
            placeholder="Enter Book Name"
            name="schema.name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.schema.name}
            error={
              formik.touched.schema?.name && Boolean(formik.errors.schema?.name)
            }
            helperText={
              formik.touched.schema?.name && formik.errors.schema?.name
            }
            required
          />

          <InputField
            placeholder="Choose Level Of Your Knowledge"
            name="level"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.level}
            error={formik.touched.level && Boolean(formik.errors.level)}
            helperText={formik.touched.level && formik.errors.level}
            required
            options={[
              {
                label: "Beginner",
                value: 1,
              },
              {
                label: "Intermediate",
                value: 2,
              },
              {
                label: "Experienced",
                value: 3,
              },
              {
                label: "5 Year Old",
                value: 4,
              },
            ]}
          />

          <InputField
            placeholder="Choose the Size of the Book"
            name="schema.size"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.schema.size}
            error={
              formik.touched.schema?.size && Boolean(formik.errors.schema?.size)
            }
            helperText={
              formik.touched.schema?.size && formik.errors.schema?.size
            }
            required
            options={[
              {
                label: "Small",
                value: 1,
              },
              {
                label: "Medium",
                value: 2,
              },
              {
                label: "Large",
                value: 3,
              },
            ]}
          />
        </>
      )}

      {step === 1 && (
        <>
          <h4 className="text-white font-bold">
            Step - {step + 1} : Enter User Details
          </h4>
          <InputField
            placeholder="Enter Your Name"
            name="user.name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user.name}
            error={
              formik.touched.user?.name && Boolean(formik.errors.user?.name)
            }
            helperText={formik.touched.user?.name && formik.errors.user?.name}
          />

          <InputField
            placeholder="Your Age"
            name="user.age"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user.age}
            error={formik.touched.user?.age && Boolean(formik.errors.user?.age)}
            helperText={formik.touched.user?.age && formik.errors.user?.age}
          />

          <InputField
            placeholder="Enter Interests (Seperated by Comma)"
            name="user.interests"
            onChange={(e) => {
              formik.setFieldValue("user.interests", e.target.value.split(","));
            }}
            onBlur={formik.handleBlur}
            value={formik.values.user.interests.toString()}
            error={
              formik.touched.user?.interests &&
              Boolean(formik.errors.user?.interests)
            }
            helperText={
              formik.touched.user?.interests &&
              formik.errors.user?.interests?.toString()
            }
            required
          />
        </>
      )}

      {step === 2 && (
        <>
          <h4 className="text-white font-bold">
            Step - {step + 1} : Create what Chapters you need and add important
            topics that you want AI to cover{" "}
          </h4>

          <h5 className="text-white my-2">
            Book Name: {formik.values.schema.name} , User Level :{" "}
            {formik.values.level}
          </h5>
          <div>
            <InputField
              placeholder="Write an optional prompt with specific instructions for the AI to follow while generating your syllabus. Additionally, you can provide a syllabus in any text format and instruct the AI to analyze and interpret its contents. (If left empty, AI will try to generate a personalized syllabus for your subject)"
              type="text"
              name="prompt"
              rows={5}
              multiline
              value={prompt}
              onChange={(e) => {
                setPrompt(e.target.value);
              }}
            />
           

           <div className="flex gap-4 items-center">
           <button
              className="flex bg-white px-4 py-1 text-sm items-center gap-2 rounded-full text-background hover:bg-gray-200 transition-colors"
              onClick={autogenerateSyllabusHandler}
            >
              Auto Generate Syllabus with AI <MdAutoFixHigh />
            </button>

             { loading && <Loading size="small" />}
           </div>

          </div>
          <div className="flex flex-col gap-2 my-4">
            {formik.values.schema.chapters.map((chapter, index) => {
              return (
                <div className="flex flex-col">
                  <div className="flex text-foreground p-1 px-4 rounded cursor-pointer font-bold">
                    <div className="flex flex-grow">
                      <span className="mr-3  m-auto text-2xl">{index+1}.</span>
                      <input
                        value={chapter.name}
                        className="bg-transparent w-full text-2xl"
                        onChange={(e) => {
                          let tChapters = [...formik.values.schema.chapters];
                          tChapters[index].name = e.target.value;
                          formik.setFieldValue("schema.chapters", tChapters);
                        }}
                      />
                    </div>
                    <button
                      className="hover:scale-125"
                      onClick={() => {
                        let tChapters = [...formik.values.schema.chapters];
                        tChapters.splice(index, 1);
                        formik.setFieldValue("schema.chapters", tChapters);
                      }}
                    >
                      <MdClose />
                    </button>
                  </div>

                  <div className="flex flex-col gap-2 mx-20 my-2 border-line border-l-2 border-dashed">
                    {chapter.topics.map((topic, tIndex) => {
                      return (
                        <div>
                          <div className="text-foreground flex p-1 px-4 rounded cursor-pointer">
                            <span className="mr-3  m-auto text-lg">{tIndex+1}.</span>
                            <input
                              value={topic.name}
                              className="bg-transparent w-full text-lg"
                              onChange={(e) => {
                                let tChapters = [
                                  ...formik.values.schema.chapters,
                                ];
                                tChapters[index].topics[tIndex].name =
                                  e.target.value;
                                formik.setFieldValue(
                                  "schema.chapters",
                                  tChapters
                                );
                              }}
                            />
                            <button
                              className="hover:scale-125"
                              onClick={() => {
                                let tChapters = [
                                  ...formik.values.schema.chapters,
                                ];
                                tChapters[index].topics.splice(tIndex, 1);
                                formik.setFieldValue(
                                  "schema.chapters",
                                  tChapters
                                );
                              }}
                            >
                              <MdClose />
                            </button>
                          </div>
                          <div className=" text-foreground flex flex-wrap gap-2 text-sm p-1 px-4 rounded cursor-pointer">
                            {/* subtopics view */}
                            {
                              topic.subtopics.map((subtopic, sIndex) => {
                                  return <div className="bg-line flex rounded-full p-2 px-4" key={topic.name+sIndex}>
                            <input
                              value={subtopic}
                              className="bg-transparent w-auto"
                              onChange={(e) => {
                                let tChapters = [
                                  ...formik.values.schema.chapters,
                                ];
                                tChapters[index].topics[tIndex].subtopics[sIndex] =
                                  e.target.value;
                                formik.setFieldValue(
                                  "schema.chapters",
                                  tChapters
                                );
                              }}
                            />
                            <button
                              className="hover:opacity-50"
                              onClick={() => {
                                let tChapters = [
                                  ...formik.values.schema.chapters,
                                ];
                                tChapters[index].topics[tIndex].subtopics.splice(sIndex, 1);
                                formik.setFieldValue(
                                  "schema.chapters",
                                  tChapters
                                );
                              }}
                            >
                              <MdClose />
                            </button>
                                  </div>
                              })
                              
                            }

                            <button className="bg-line text-foreground flex rounded-full p-2 px-4 hover:scale-105 transition-transform text-xsm font-bold uppercase" onClick={() =>{
                               let tChapters = [...formik.values.schema.chapters];
                               tChapters[index].topics[tIndex].subtopics.push("New Subtopic");
                               formik.setFieldValue("schema.chapters", tChapters);
                            }}>
                                Add Subtopic <MdAdd size={20}/>
                            </button>
                          </div>
                        </div>
                      );
                    })}

                
                    <div
                      className="flex text-foreground items-center p-1 px-4"
                    >
                      <button className="bg-line text-foreground flex items-center gap-2 rounded-full p-2 px-4 hover:scale-105 transition-transform text font-bold uppercase" onClick={() =>{
                                 let tChapters = [...formik.values.schema.chapters];
                                 tChapters[index].topics.push({
                                   name: "New Topic",
                                   subtopics: [],
                                 });
                                 formik.setFieldValue("schema.chapters", tChapters);
                            }}>
                                Add Topic <MdAdd size={24}/>
                            </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              className="flex gap-2 items-center bg-line text-foreground text-xl px-4 py-2 rounded-full hover:bg-primary-gradient cursor-pointer text-left hover:scale-[1.005] transition-transform uppercase font-bold"
              onClick={() => {
                let tChapters = [...formik.values.schema.chapters];
                tChapters.push({
                  name: "New Chapter",
                  topics: [],
                });
                formik.setFieldValue("schema.chapters", tChapters);
              }}
            >
              Add New Chapter <MdAdd size={30}/>
            </button>
          </div>
        </>
      )}

      <div className="flex gap-4 items-center">
        <Button
          label="Back"
          onClick={() => setStep(step - 1)}
          disabled={step === 0}
          outlined
        />
        <Button
          disabled={!formik.values.schema.name}
          label={step === TOTAL_STEP - 1 ? "Generate Book" : "Next"}
          onClick={() => {
            if (step === TOTAL_STEP - 1) {
              console.log(formik.errors)
              formik.submitForm();
            } else {
              setStep(step + 1);
            }
          }}
          //   disabled={step === TOTAL_STEP - 1}
        />
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default CreateBookSchema;
