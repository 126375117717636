import { useKunji } from "kunji-react";
import Button from "./Button";

function LoginToContinue() {
    const {initiateAuthentication} = useKunji()
    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="bg-line w-1/3 px-10 py-5 flex flex-col gap-4 min-h-[20rem] items-center justify-center rounded-md">
                <h1 className="text-center font-bold text-2xl text-white">Login to do More</h1>
                <p className="text-center text-sm text-gray-400">Please authenticate to access all the features of Superbook</p>
                <Button onClick={initiateAuthentication} label="Login or Create Account"/>
            </div>
        </div>
    );
}

export default LoginToContinue;