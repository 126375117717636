import { useState } from "react";
import Toggle from "react-toggle";
import { FaMoon } from "react-icons/fa";
import { BsSunFill } from "react-icons/bs";
import useTheme from "../hooks/useTheme";

function ThemeToggle(props : {className?: string}) {
    const {theme, setTheme} = useTheme()
    
    return (
        <div
        className={"flex " + props.className}
        >

<div className="flex gap-2 items-center flex-grow">
            {
                theme.mode === 'dark' ? <>
                <FaMoon/> Dark</> : <><BsSunFill/>  Light</>
            }
        </div>

        <Toggle
            color="red"
            defaultChecked={theme.mode === 'dark'}
            icons={false}
            onChange={() => {
                setTheme({...theme, mode: theme.mode === 'light' ? 'dark' : 'light'})
            }} />

        </div>
    );
}

export default ThemeToggle;