import { useState } from "react";
import { Book } from "../../constants/Types";
import BookComponent from "../BookComponent";
import { MdMenu } from "react-icons/md";

const HEADER_HEIGHT = '4rem' // line-height of 2rem + 2rem margins

function BookReader(props: { book: Book }) {
    const { book } = props
    const [isMenuVisible, setMenu] = useState(false)

    return (
        <div className="flex flex-col text-foreground items-center h-screen">
              <header className="font-bold text-2xl my-4 fixed flex w-full">
                <div className=""></div>
                <div className="ml-16 flex-grow text-center">{book.name}</div>
                <div className="">
                    <button className="px-4" onClick={() => {
                        setMenu(!isMenuVisible)
                    }}><MdMenu/></button>
                </div>
              </header>
              <BookComponent book={book} className="w-screen h-screen pt-16" isMenu={isMenuVisible} />
        </div>
    );
}

export default BookReader;