import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import {
  Book,
  SearchIndexResult,
  chapterPopulated,
  topicPopulated,
} from "../constants/Types";
import {
  getChapterById,
  getExplanationFromGPT,
  searchBook,
  updateTopicById,
} from "../services/book.service";
import Loading from "./Loading";
import InputField from "./InputField";
import BookIndex from "./BookIndex";
import axios from "axios";
import BookContentSkeleton from "./skeletons/BookContentSkeleton";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"
import NoteEditor from "./book-reader/NoteEditor";
import MenuPanel from "./MenuPanel";
import useTheme from "../hooks/useTheme";
import MarkdownView from "./MarkdownView";

interface Definition {
  word: string;
  meanings: {
    example: string;
    definition: string;
  }[];
}

async function getWordMeaning(word: string): Promise<Definition | null> {
  try {
    // https://dictionaryapi.dev/
    const response = await axios.get(
      `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
    );

    if (response.status === 200) {
      const data = response.data[0]; // Use the first definition if available
      if (data && data.meanings) {
        const meanings = data.meanings.map(
          (meaning: any) => meaning.definitions[0]
        );
        return {
          word: data.word,
          meanings,
        };
      }
    }
  } catch (error) {
    console.error("Error fetching word meaning:", error);
  }
  return null;
}

const apiKey = "aSANkBs8s9d-VdrTJ6VjCHtP2Q03SiHjs-87XlC_eDA";

async function getSingleImageForTopic(topic: string) {
  try {
    const response = await axios.get(`https://api.unsplash.com/search/photos`, {
      params: {
        query: topic,
        orientation: "landscape", // Filter for landscape images
        per_page: 1, // Limit to 1 result
      },
      headers: {
        Authorization: `Client-ID ${apiKey}`,
      },
    });

    if (response.status === 200) {
      const photos = response.data.results;

      if (photos.length > 0) {
        return photos[0].urls.small; // Use 'small' for a smaller size
      }
    }
  } catch (error) {
    console.error("Error fetching images:", error);
  }

  return null; // Return null if no image found
}

function BookComponent(props: { book: Book, className: string, isMenu: boolean }) {
  const { book } = props;
  const [loading, setLoading] = useState(false);
  const [activeChapterIndex, setActiveChapter] = useState(0);
  const [activeTopicId, setActiveTopicId] = useState("");
  const [query, setQuery] = useState("");
  const [activeSearchResult, setActiveSearchResult] = useState<number>(-1);
  const [searchResults, setSearchResults] = useState<SearchIndexResult[]>([]);
  const [chapter, setChapter] = useState<chapterPopulated>();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const {theme} = useTheme();
  
  // const [panel1, setPanel1] = useState<{visible: boolean, type: 'INDEX'}>({
  //   visible: true,
  //   type: "INDEX"
  // })

  // const [panel2, setPanel2] = useState<{visible: boolean, type: 'BOOK'}>({
  //   visible: true,
  //   type: "BOOK"
  // })

  // const [panel3, setPanel3] = useState<{visible: boolean, type: 'NOTEPAD' | 'MENU'}>({
  //   visible: false,
  //   type: "NOTEPAD"
  // })

  const [selectedText, setSelectedText] = useState<{
    text: string;
    answer?: string;
    isLoading?: boolean;
  } | null>(null);

  const [miniToolbarPosition, setMiniToolbarPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setSelectedText({
        text: selection.toString(),
      });
      // toolbarRef.
      setMiniToolbarPosition({
        top: rect.top - 40, // Adjust the top position to place the toolbar above the selected text
        left: rect.left + rect.width / 2, // Center the toolbar horizontally above the selected text
      });
    } else {
      // Reset selected text and hide the toolbar when no text is selected
      setSelectedText(null);
      setMiniToolbarPosition({ top: 0, left: 0 });
    }
  };

  const searchMeaning = async () => {
    // Implement the logic to search for the meaning of a single word
    if (selectedText) {
      setSelectedText({
        ...selectedText,
        isLoading: true,
      });
      const data = await getWordMeaning(selectedText.text);

      if (data) {
        setSelectedText({
          ...selectedText,
          answer: `
          Meaning: ${data?.meanings[0].definition}
          
          Example: ${data?.meanings[0].example ?? "Not Available"}
          `,
          isLoading: false,
        });
      } else {
        setSelectedText({
          ...selectedText,
          answer: "Not Found",
          isLoading: false,
        });
      }
    }
  };

  const searchExplanation = async () => {
    // Implement the logic to search for the meaning of a single word
    if (selectedText) {
      try {
        setSelectedText({
          ...selectedText,
          isLoading: true,
        });
        const data = await getExplanationFromGPT(selectedText.text);

        setSelectedText({
          ...selectedText,
          answer: "Explanation: " + data,
          isLoading: false,
        });
      } catch (error) {
        setSelectedText({
          ...selectedText,
          isLoading: false,
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleTextSelection);

    return () => {
      document.removeEventListener("mouseup", handleTextSelection);
    };
  }, []); // Ensure the event listener is added and removed only once

  const updateTopic = async (
    topic: topicPopulated,
    operation: "LEVEL" | "LANGUAGE" | "NOTES",
    index: number
  ) => {
    if (!chapter) return;
    try {
      setLoading(true);
      const res = await updateTopicById(topic, operation);
      setLoading(false);
      const tChapter = { ...chapter };
      tChapter.topics[index] = res;
      setChapter(tChapter);
    } catch (error) {
      toast.error("Something Went Wrong");
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const api = async (chapterId: string) => {
      try {
        setLoading(true);
        const res = await getChapterById(chapterId);
        setLoading(false);
        const imageUrl = await getSingleImageForTopic(book.name);
        setCoverImage(imageUrl);
        setChapter(res);
      } catch (error) {
        toast.error("Something Went Wrong");
        console.error(error);
        setLoading(false);
      }
    };
    api(book.chapters[activeChapterIndex]);
  }, [book, activeChapterIndex]);

  useEffect(() => {
    if (!activeTopicId) return;
    const element = document.getElementById(activeTopicId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
      setActiveTopicId("");
    }
  }, [activeTopicId, chapter]);

  useEffect(() => {
    if (activeSearchResult < 0) return;
    const index = book.chapters.findIndex(
      (ch) => ch === searchResults[activeSearchResult].chapterId
    );
    if (index !== -1) {
      setActiveChapter(index);
    }
    setActiveTopicId(searchResults[activeSearchResult].topicId);
  }, [
    activeSearchResult,
    book.chapters,
    searchResults,
    setActiveChapter,
    setActiveTopicId,
  ]);

  const searchHandler = async () => {
    if (!query) return;
    try {
      setLoading(true);
      const res = await searchBook(book.id, query);
      setLoading(false);
      setSearchResults(res);
      if(res.length > 0){
        setActiveSearchResult(0);
      }
    } catch (error) {
      toast.error("Something Went Wrong");
      console.error(error);
      setLoading(false);
    }
  };

  const [coverImage, setCoverImage] = useState("");


  const indexComponent = <div className="w-full h-full">
    <BookIndex
      className="w-full h-full"
      bookId={book.id}
      onChapterChange={(chapterId) => {
        const index = book.chapters.findIndex((ch) => ch === chapterId);
        if (index !== -1) {
          setActiveChapter(index);
        }
      }}
      onTopicChange={(topicId) => {
        setActiveTopicId(topicId);
      }}
    />
  </div>

/* Mini toolbar */
const miniToolbar = <div
    className={
      "fixed dark:bg-foreground bg-background p-2 rounded shadow transition-all text-sm text-white dark:text-background " +
      (selectedText
        ? "scale-100 opacity-100 z-50"
        : "scale-50 opacity-0 -z-40")
    }
    ref={toolbarRef}
    style={{
      top: miniToolbarPosition.top,
      left: miniToolbarPosition.left,
    }}
  >
    <div className="">
      {selectedText && selectedText.text.split(" ").length === 1 && (
        <button className="mr-2  hover:underline" onClick={searchMeaning}>
          Search Meaning
        </button>
      )}
      <button className=" hover:underline" onClick={searchExplanation}>
        Explain Me!
      </button>
    </div>

    {selectedText && selectedText.isLoading && (
      <div className="text-gray-400 py-1 italic">
        <Loading primary size="small" />
      </div>
    )}

    {selectedText && selectedText.answer && (
      <div className="text-gray-400 dark:text-gray-900 py-1 italic">
        {selectedText.answer}
      </div>
    )}
  </div>


  const bookComponent =    <div className="overflow-scroll">

  {chapter ? (
    <div className="w-full rounded bg-foreground dark:bg-line dark:text-foreground text-black p-4 h-full selection:bg-highlight dark:selection:bg-foreground dark:selection:text-highlight selection:text-foreground">
      <div className="w-full relative my-4">
        <input
          type="text"
          name="search"
          placeholder="Search any Topic or Content in Book"
          value={query}
          className="px-4 py-2 bg-gray-200 dark:bg-background dark:text-foreground rounded-lg w-full outline-none border-none"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") searchHandler();
          }}
        />
        <div className="flex gap-4 absolute right-0 bottom-0 mx-4 items-center h-full text-gray-500 ">
          {activeSearchResult > -1 && (
            <>
              {" "}
              Results:{" "}
              {activeSearchResult + 1 + "/" + searchResults.length}
            </>
          )}

          <button
            className={
              "" +
              (activeSearchResult !== 0
                ? " text-gray-500 "
                : " text-purple-500 hover:underline ")
            }
            onClick={() => {
              if (activeSearchResult !== 0)
                setActiveSearchResult(activeSearchResult - 1);
            }}
          >
            Prev
          </button>
          <button
            className={
              "" +
              (activeSearchResult < 0 ||
              activeSearchResult > book.chapters.length
                ? " text-gray-500 "
                : " text-purple-500 hover:underline ")
            }
            onClick={() => {
              if (
                activeSearchResult > -1 &&
                activeSearchResult <= book.chapters.length
              )
                setActiveSearchResult(activeSearchResult + 1);
            }}
          >
            Next
          </button>
        </div>
      </div>

      <div className="flex justify-center">
        {loading && <Loading primary />}
      </div>

      <div className="flex">
        <button
          className={
            "" +
            (activeChapterIndex === 0
              ? " text-gray-300 "
              : " text-purple-500 hover:underline ")
          }
          onClick={() => {
            if (activeChapterIndex !== 0)
              setActiveChapter(activeChapterIndex - 1);
          }}
        >
          Previous Chapter
        </button>
        <h1 className="flex-grow font-bold text-center text-xl underline my-4">
          Chapter {activeChapterIndex + 1 + " : " + chapter.name}
        </h1>
        <button
          className={
            "" +
            (activeChapterIndex === book.chapters.length - 1
              ? " text-gray-300 "
              : " text-purple-500 hover:underline ")
          }
          onClick={() => {
            if (activeChapterIndex !== book.chapters.length - 1)
              setActiveChapter(activeChapterIndex + 1);
          }}
        >
          Next Chapter
        </button>
      </div>

      {chapter.topics.map((topic, index) => {
        return (
          <div key={index}>
            <div className="flex items-center">
              <h4 className="font-bold flex-grow" id={topic.id}>
                {index + 1 + ". " + topic.name}
              </h4>
              <InputField
                placeholder="Choose Level Of Your Knowledge"
                className="w"
                name="level"
                size="small"
                onChange={(e) => {
                  const tchapter = { ...chapter };
                  tchapter.topics[index].level = e.target.value;
                  updateTopic(tchapter.topics[index], "LEVEL", index);
                  setChapter(tchapter);
                }}
                // onBlur={formik.handleBlur}
                value={topic.level ?? book.level}
                // error={formik.touched.level && Boolean(formik.errors.level)}
                // helperText={formik.touched.level && formik.errors.level}
                required
                options={[
                  {
                    label: "Beginner",
                    value: 1,
                  },
                  {
                    label: "Intermediate",
                    value: 2,
                  },
                  {
                    label: "Experienced",
                    value: 3,
                  },
                  {
                    label: "5 Year Old",
                    value: 4,
                  },
                ]}
              />

              <InputField
                placeholder="Language"
                className="mx-2"
                size="small"
                name="language"
                onChange={(e) => {
                  const tchapter = { ...chapter };
                  tchapter.topics[index].language = e.target.value;
                  updateTopic(tchapter.topics[index], "LANGUAGE", index);
                  setChapter(tchapter);
                }}
                // onBlur={formik.handleBlur}
                value={topic.language ?? "English"}
                // error={formik.touched.level && Boolean(formik.errors.level)}
                // helperText={formik.touched.level && formik.errors.level}
                required
                options={[
                  {
                    label: "Hindi",
                    value: "Hindi",
                  },
                  {
                    label: "English",
                    value: "English",
                  },
                  {
                    label: "Hinglish",
                    value: "Hinglish",
                  },
                ]}
              />
            </div>
            <div className="p-4">
              {topic.image && (
                <img
                  src={topic.image}
                  alt={topic.name}
                  className="mb-8 max-w-[600px] rounded-lg"
                />
              )}
              <MarkdownView>
                {topic.answer}
              </MarkdownView>

              {topic.notes ? (
                <div className="relative">
                  <NoteEditor
                    placeholder="Writes Notes, Remarks or anything!"
                    type="text"
                    name="notes"
                    multiline
                    rows={8}
                    value={topic.notes}
                    onChange={(e) => {
                      const tchapter = { ...chapter };
                      tchapter.topics[index].notes = e.target.value;
                      setChapter(tchapter);
                    }}
                    onSave={() => {
                      updateTopic(chapter.topics[index], "NOTES", index);
                    }}
                  />

                </div>
              ) : (
                <button
                  className="bg-gray-200 dark:bg-background dark:text-foreground p-2 rounded-full px-6 text-gray-500 cursor-pointer my-4 text-sm font-bold"
                  onClick={() => {
                    const tchapter = { ...chapter };
                    tchapter.topics[index].notes = "Your Notes";
                    // updateTopic(tchapter.topics[index], "LANGUAGE", index);
                    setChapter(tchapter);
                  }}
                >
                  NOTE +
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <BookContentSkeleton />
  )}
</div>

  return (
    <div className={props.className}>
      <PanelGroup autoSaveId="main-panel" direction="horizontal" className="gap-2 px-4 h-full">
        {/* Add toolbar here */}
          {miniToolbar}

        {/* Fixed Panel */}
      <Panel defaultSize={20} style={{ overflow: 'scroll'}} className="relative"  id="panel-1" order={1}>
        {indexComponent}
      </Panel>

      <PanelResizeHandle className="flex justify-center items-center">
        <div className="w-1 h-8 bg-gray-400 rounded-full"></div>
         </PanelResizeHandle>

      <Panel defaultSize={80} minSize={50} style={{overflow: 'scroll'}} className="overflow-scroll" id="panel-2" order={2}>
          {bookComponent}
      </Panel>


      {
        props.isMenu && <>
        <PanelResizeHandle className="flex justify-center items-center">
        <div className="w-1 h-8 bg-gray-400 rounded-full"></div>
         </PanelResizeHandle>
        <Panel defaultSize={10} minSize={10} id="panel-3" order={3}>
        {
          <MenuPanel book={book} />
        }
      </Panel>
      </>
      }
      
     
      </PanelGroup>
      <div className="col-span-3"></div>
    </div>
  );
}

export default BookComponent;
